<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import draggable from 'vuedraggable';

export default {
	name: "BoWineriesLogo",
	extends: Base,
	components: {
	   draggable,
	},
	data(){
	return {
		Name:"BoWineriesLogo",
	}
	},
	mounted(){
		this.$set(this.$root, 'page', this)
		this.refreshData()
	},
	watch:{
		'$route.query'(){
			this.refreshData()
		},
		'filter.level'(){
			this.search()
		},
	},
	methods:{
		endDrag(){
            BOGen.apirest('/'+this.Name, {data:this.data.data,type:'sort'}, (err,resp)=>{
            console.log(resp)
            }, "POST");
        }, 
	}
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">

                <div class="card-body">
                	<VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
                				<h5 class="card-title">{{ObjectName}} List</h5>
							</div>
							<div class="col-sm-3">
							</div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                	<draggable class="row justify-content-center" v-model="data.data" @end="endDrag" style="cursor:pointer;">
		                <div class="col-lg-2 col-md-4 col-xs-6" v-for="(v,k) in data.data" :key="k">
		                     <div class="card">
		                        <div class="card-body">
		                           <div class="product-img">
		                              	<img :src="uploader(v.awl_image)" style="width: 100%;">
		                              	<div class="pro-img-overlay">
			                              	<router-link style="margin: 34% 5px;" role="button" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'" class="bg-info"><i class="fas fa-pencil-alt"></i></router-link>
                           					<a style="margin: 34% 5px;" href="javascript:void(0)" class="bg-danger" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
		                              	</div>
		                           		<span class="label label-success" v-if="v.awl_is_active == 'Y'">Active</span>
		                           		<span class="label label-danger" v-else>Inactive</span>
								   </div>
		                        </div>
		                     </div>
		                </div>
		            </draggable>
                </div>
				<div class="panel-body">
					<div class="row" v-if="NotFound">
						<div class="col-12 text-center" style="padding: 20px;">
							<h3 class="tc">{{NotFound}}</h3>
						</div>
					</div>
					<div class="row" v-if="data.data===false">
						<div class="col-12 text-center">
							<LoadingSpinner light></LoadingSpinner>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
             </div>
          </div>
       </div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
                      <div class="col-md-8">
                         <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-3">
							<BoField name="awl_image" mandatory>
								<Uploader name="awl_image" type="clients" uploadType="cropping" :param="{thumbnail:true}" v-model="row.awl_image"></Uploader>
							</BoField>
						</div>
						<div class="col-6">							
							<BoField name="awl_client_name" v-model="row.awl_client_name"></BoField>
							<div class="form-group">
                             <label class="control-label">Link Website</label>
                                <div class="input-group mb-3">
                                   <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon1"><i class="fas fa-link"></i></span>
                                   </div>
                                   <input type="url" v-model="row.awl_link" class="form-control" placeholder="" aria-label="price" aria-describedby="basic-addon1">
                                </div>
                             </div>
							<BoField name="awl_is_active">
								<div class="row">
									<div class="col-2"><radio name="awl_is_active" v-model="row.awl_is_active" option="Y" :attr="validation('awl_is_active')">Active</radio></div>
									<div class="col-2"><radio name="awl_is_active" v-model="row.awl_is_active" option="N">Inactive</radio></div>
								</div>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded">Save Wineries</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
